import React from "react"
import PropTypes from "prop-types"
import GTM from "@src/services/gtm"
import ToursLayout from "@src/layouts/tours"
import { PageSeoPropTypes } from "@src/components/page-helmet"
import { TourDetailTilePropTypes } from "@src/components/content-tour-detail-tile"
import { orderByField } from "@src/utils/sorting"

const PageTemplate = ({
  pageContext: {
    data: { tenTours: data },
    ...pageContext
  },
}) => {
  const {
    tourCategory: {
      title,
      name,
      seo: pageSeo,
      seoTextTop,
      faq,
      seoTextBottomNew,
    },
    tours: { nodes: tourData },
  } = data

  const {
    basePath: pageUri,
    pageNumber,
    pageCount,
    postsPerPage: toursPerPage,
    postsTotal: totalTours,
    postIds: toursOrder,
    categoryId,
  } = pageContext
  GTM.dataLayerPushPageInfo({
    template: "tour-category",
  })

  const TOURS_LAYOUT_TOP_TEXT_DEFAULT = `Explore ${name.toLowerCase()} tours around the world below.`
  const textTop =
    pageNumber > 1
      ? null
      : seoTextTop
      ? seoTextTop
      : TOURS_LAYOUT_TOP_TEXT_DEFAULT
  return (
    <ToursLayout
      pageName={title || `${name} Tours`}
      pageSeo={{ ...pageSeo, metaRobotsNoindex: "index" }}
      headerText={textTop}
      tourData={tourData?.sort(orderByField("id", toursOrder))}
      toursParentUri={pageUri}
      toursPageNumber={pageNumber}
      toursPageCount={pageCount}
      toursPerPage={toursPerPage}
      totalTours={totalTours}
      categoryId={categoryId}
      faqs={faq}
      seoTextBottomNew={seoTextBottomNew}
    />
  )
}

PageTemplate.propTypes = {
  pageContext: PropTypes.shape({
    basePath: PropTypes.string,
    pageNumber: PropTypes.number,
    pageCount: PropTypes.number,
    postsPerPage: PropTypes.number,
    postsTotal: PropTypes.number,
    postIds: PropTypes.arrayOf(PropTypes.number),
    categoryId: PropTypes.number,
    data: PropTypes.shape({
      tenTours: PropTypes.shape({
        tourCategory: PropTypes.shape({
          title: PropTypes.string,
          name: PropTypes.string,
          uri: PropTypes.string,
          seo: PropTypes.shape({ ...PageSeoPropTypes }),
          seoTextTop: PropTypes.string,
          faq: PropTypes.shape({
            title: PropTypes.string,
            faqs: PropTypes.arrayOf(
              PropTypes.shape({
                question: PropTypes.string,
                answer: PropTypes.string,
              })
            ),
          }),
          seoTextBottomNew: PropTypes.arrayOf(
            PropTypes.shape({
              content: PropTypes.string,
              title: PropTypes.string,
            })
          ),
        }),
        tours: PropTypes.shape({
          nodes: PropTypes.arrayOf(
            PropTypes.shape({
              ...TourDetailTilePropTypes,
            })
          ),
        }),
      }),
    }),
  }),
}

export default PageTemplate
